<template>
  <div class="validation">
    <div class="validation__message">
      <ValidationMessage headline="VALIDIERUNG" text="Dein Gutschein ist jetzt gültig.
Möchtest du ihn einlösen?"
                         :if-accepted-fn="validate"
                         :if-rejected-fn="reject"/>
    </div>
  </div>
</template>

<script>
import ValidationMessage from '@/components/ValidationMessage.vue'
export default {
  name: 'Validation',
  components: {
    ValidationMessage
  },
  props: {
    goto: {type: Function}
  },
  methods: {
    reject: function() {
      this.goto('prize')
    },
    validate: function() {
      this.goto('validation2');
    }
  },
  mounted: function() {
    store.set('currentPage', 'Validation');
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.validation {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-color;
  z-index: 3;
}
</style>
